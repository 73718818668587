<template>
  <div>
    <div class="imgView">
      <img :src="ultra.rpa_swiper_img" alt="">
    </div>
    <!--  基础信息  -->
    <section class="rpa-info">
      <section>
        <div class="rpa-title">{{ultra.rpa_title}}</div>
        <div class="rpa-s-title">{{ultra.rpa_s_title}}</div>
      </section>
      <!--  优点  -->
      <section class="rpa-advantage-section">
        <div class="rpa-advantage">
          <div class="advantage" v-for="(item,index) in advantage" :key="index">
            <div class="imgView">
              <img :src="item.pic" alt="">
            </div>
            <div class="advantage-title">{{item.title}}</div>
            <div class="advantage-description">{{item.description}}</div>
          </div>
        </div>
      </section>
      <!--  描述  -->
      <section class="rpa-ultra-description-section">
        <div class="section-inner-wrap">
          <div class="rpa-description-container">
            <div class="rpa-description" v-html="ultra.description"></div>
            <div class="imgView">
              <img :src="ultra.description_img" alt="">
            </div>
          </div>
        </div>
      </section>
    </section>
    <!--  橡胶分析的新高度图  -->
    <section class="rpa-rubber-analysis-section"
             :style="{
        backgroundImage: 'url('+backgroudImg+')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }"
    >
      <div class="rubber-analysis-title-container">
        <div class="rubber-analysis-title">{{ultra.rubber_analysis_title}}</div>
        <div class="rubber-analysis-s-title">{{ultra.rubber_analysis_s_title}}</div>
        <div class="rubber-analysis-tip">{{ $t("localization.rpa.rubber_analysis_tips") }}</div>
      </div>
      <div class="rubber-analysis-icon" :class="item.className" v-for="(item,index) in rubberAnalysis" :key="index">
        <div>
          <img src="@/assets/images/rpa/rubber_analysis_icon.png" alt="" @click="handleShowDescription(item.id)">
        </div>
        <div v-show="item.id === showId" id="rubber-analysis" class="rubber-analysis-popover" :class="item.popClassName" :style="{display: 'block', height: item.height + 'px'}">
          <div class="popover-close" @click="handleShowDescription(0)">
            <i class="el-icon-error"></i>
          </div>
          <div class="rubber-analysis-description" :class="item.descriptionClassName" v-html="item.description.replace(/\n/g, '<br>')"></div>
        </div>
      </div>
    </section>
    <!--  人物  -->
    <section class="twoView">
      <div class="swiperView" v-if="people.length > 0">
        <swiper :options="swiperOptionTwo" ref="mySwiperOne">
          <swiper-slide class="swiperItem" v-for="(item, index) in people" :key="index">
            <div class="txtView">
              <div class="name">{{item.name}}</div>
              <div class="name-other">{{item.company || ' '}}</div>
              <div class="name-other">{{item.position || ' '}}</div>
              <div class="name-other">{{item.duty || ' '}}</div>
              <p v-html="item.description.replace(/\n/g, '<br>')"></p>
            </div>
            <div class="imgView">
              <img :src="item.attachment_url" alt="" />
            </div>
          </swiper-slide>
          <div class="swiper-button-prev swiper_btn" slot="button-prev">
            <img class="swiper-button-prev-img" src="@/assets/images/rpa/prev.png" alt="" v-show="showPrev === true" @mouseover="handleShowPrev(false)" />
            <img class="swiper-button-prev-img" src="@/assets/images/rpa/prev_hover.png" alt="" v-show="showPrev === false" @mouseout="handleShowPrev(true)" />
          </div>
          <div class="swiper-button-next swiper_btn" slot="button-next">
            <img class="swiper-button-prev-img" src="@/assets/images/rpa/next.png" alt="" v-show="showNext === true" @mouseover="handleShowNext(false)" />
            <img class="swiper-button-prev-img" src="@/assets/images/rpa/next_hover.png" alt="" v-show="showNext === false" @mouseout="handleShowNext(true)" />
          </div>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </section>
    <!--  描述折叠面板  -->
    <section class="rpa-description-section">
      <el-collapse v-model="collapseActive" accordion>
        <el-collapse-item :title="item.title" :name="item.id" v-for="(item, index) in description" :key="index">
          <div class="collapse-rpa-description" v-html="item.description"></div>
        </el-collapse-item>
      </el-collapse>
    </section>
    <!--  视频  -->
    <section class="rpa-ultra-video-section">
      <div class="rpa-ultra-video-container">
        <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="false" :options="videoPlayerOptionsFun(ultra.vidio)"></video-player>
      </div>
    </section>
    <!--  橡胶分析仪图  -->
    <section class="rpa-ultra-img-section">
      <div class="imgView">
        <img :src="ultra.rpa_img" alt="">
      </div>
    </section>
    <!--  pdf  -->
    <section class="rpa-pdf-section">
      <div class="rpa-pdf-container">
        <div class="rpa-pdf-description" v-html="ultra.pdf_description"></div>
        <div class="rpa-pdf-download-container">
          <div class="rpa-pdf-download-title-container">
            <div class="imgView">
              <img src="@/assets/images/rpa/pdf_down.png" alt="">
            </div>
            <div class="rpa-pdf-download-title">{{ $t("localization.rpa.pdf_download_title") }}</div>
          </div>
          <div class="rpa-pdf-download-tips">{{ $t("localization.rpa.pdf_download_tips") }}</div>
          <div class="rpa-pdf-brochure">
            <a target="_blank" :href="ultra.pdf_brochure" class="rpa-pdf-brochure-link">{{ultra.pdf_brochure_title}}</a>
          </div>
          <div class="rpa-pdf-test">
            <a target="_blank" :href="ultra.pdf_test" class="rpa-pdf-test-link">{{ultra.pdf_test_title}}</a>
          </div>
        </div>
      </div>
    </section>
    <!--  关于我们联系我们  -->
    <section class="rpa-about-contact-section">
      <div class="about-contact-container">
        <div class="about-container">
          <div class="imgView">
            <img :src="ultra.about_us_img" alt="">
          </div>
          <div class="about-us-title">{{ $t("localization.rpa.about_us_title") }}</div>
          <router-link to="/about" class="about-us-link">{{ $t("localization.rpa.about_us") }}</router-link>
        </div>
        <div class="contact-container">
          <div class="imgView">
            <img :src="ultra.contact_us_img" alt="">
          </div>
          <div class="contact-us-title">{{ $t("localization.rpa.contact_us_title") }}</div>
          <router-link to="/product_consulting" class="contact-us-link">{{ $t("localization.rpa.contact_us") }}</router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import { swiper, swiperSlide } from "vue-awesome-swiper";
  import "swiper/dist/css/swiper.css";
  import { videoPlayer } from "vue-video-player";
  import "video.js/dist/video-js.css";
  export default {
    name: "RpaUltra",
    components: {
      swiper,
      swiperSlide,
      videoPlayer
    },
    data() {
      return {
        ultra: {},          //页面信息
        advantage: [],      //优点
        description: [],    //rpa介绍折叠页
        people: [],         //人物轮播
        rubberAnalysis: [], //橡胶分析的新高度
        swiperOptionTwo: {
          loop: true,
          // autoplay: {
          //   delay: 6000,
          //   stopOnLastSlide: false,
          //   disableOnInteraction: false,
          // },
          autoplay: false,
          // 设置点击箭头
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        },
        videoPlayerOptions: {},
        collapseActive: '0',//折叠面板激活
        showId: 0,   //展示描述弹窗
        showPrev: true,
        showNext: true,
        backgroudImg: ''
      }
    },
    computed: {
      swiper() {
        return this.$refs.mySwiperOne.swiper;
      },
    },
    mounted() {
      this.getRpa();
    },
    methods: {
      /**banner视频 */
      videoPlayerOptionsFun(video_url) {
        const videoPlayerOptions = {
          playbackRates: [0.75, 1.0, 1.25, 1.5, 2.0], //播放速度
          autoplay: true, // 是否自动播放。
          muted: true, // 是否静音播放，默认情况下将会消除任何音频。
          loop: true, // 是否循环播放。
          preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 是否流体从而按比例缩放以适应其容器。
          sources: [
            {
              type: "video/mp4",
              src: video_url, // 视频url地址
            },
          ],
          poster: '', // 封面地址
          width: "100%",
          notSupportedMessage: "此视频暂无法播放...", // 当无法播放时允许覆盖Video.js，显示的默认信息。
          controlBar: {
            timeDivider: false, //当前时间和持续时间的分隔符
            durationDisplay: false, //显示持续时间
            remainingTimeDisplay: false, //是否显示剩余时间功能
            fullscreenToggle: false, //全屏按钮
          },
        };
        return videoPlayerOptions;
      },
      getRpa(){
        this.$http.getRpaUltra().then((res) => {
          if (res.code == 200) {
            this.ultra = res.data.ultra;
            this.backgroudImg = this.ultra.rubber_analysis_img;
            this.advantage = res.data.advantage;
            this.description = res.data.description;
            this.people = res.data.people;
            this.rubberAnalysis = res.data.rubberAnalysis;
            this.rubberAnalysis.forEach( (item,index) =>{
              item.height = 125;
              if (0 <= parseInt(item.description.length) && parseInt(item.description.length) < 100){
                item.height = 125;
              }
              else if (100 <= parseInt(item.description.length) && parseInt(item.description.length) < 200){
                item.height = 205;
              }
              else if (200 <= parseInt(item.description.length) && parseInt(item.description.length) < 250){
                item.height = 280;
              }
              else if (250 <= parseInt(item.description.length) && parseInt(item.description.length) < 300){
                item.height = 300;
              }
              else if (300 <= parseInt(item.description.length) && parseInt(item.description.length) < 400){
                item.height = 400;
              }

              if (item.id === 1){
                item.className = 'rubber-analysis-icon-one';
                item.popClassName = 'rubber-analysis-popover-one';
                item.descriptionClassName = 'rubber-analysis-description-one';
              }
              else if (item.id === 2){
                item.className = 'rubber-analysis-icon-two';
                item.popClassName = 'rubber-analysis-popover-two';
                item.descriptionClassName = 'rubber-analysis-description-two';
              }
              else if (item.id === 3){
                item.className = 'rubber-analysis-icon-three';
                item.popClassName = 'rubber-analysis-popover-three';
                item.descriptionClassName = 'rubber-analysis-description-three';
              }
              else if (item.id === 4){
                item.className = 'rubber-analysis-icon-four';
                item.popClassName = 'rubber-analysis-popover-four';
                item.descriptionClassName = 'rubber-analysis-description-four';
              }
            })
          } else {
            this.$message.error(res.msg);
          }
        });
      },
      handleShowDescription(id){
        this.showId = id;
      },
      handleShowPrev(status){
        this.showPrev = status;
      },
      handleShowNext(status){
        this.showNext = status;
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/style/rpaUltra.scss";
  /deep/ .vjs-control-bar {
    display: none;
  }
  /*/deep/ .video-js .vjs-tech {*/
  /*  object-fit: fill;*/
  /*}*/
  /deep/ .vjs-poser {
    background-size: cover;
  }
</style>